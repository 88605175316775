
.bottomBorder{
    border-bottom: 4px solid rgb(203, 202, 202);
}
.grayBg{
    background-color: rgba(244, 241, 241, 0.947);
}
.header{
    border-bottom: 0.1rem solid rgb(192, 188, 188)!important;
}
.row{
    /* border-top: 0.1rem solid rgb(192, 188, 188)!important;  */
    /* border-bottom: 0.1rem solid rgb(192, 188, 188)!important; */
    /* font-family: 'Mulish', sans-serif; */
    /* font-weight: bold; */
    /* font-size: small; */
}
.btn{
    background-color: #ff7f00!important;
    color: #fff!important;

}
.btn:hover{
    /* background-color: #ff4400; */
}
.errorBorder{
    border-color: red!important;
}
.errorText{
    color: red!important;
}

