.headerNav{
    width: 100%;
    max-width: 100%;
    background-color: #191e22;
    /* background-color: #36454F; */
    height: 60px;
    max-height: 100px;
    display: flex;
    justify-content: left;
    align-items: center;
    /* padding-left: 10%; */
}
.headerLogoName1{
    
    color: rgb(10, 204, 10);
}

.headerLogoName2{
    color: rgb(252, 187, 10);
}
