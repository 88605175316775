.wraper{
    /* width: 30%;
    margin: auto;
    margin-top: 5%;
    border-radius: 1em; */
    /* border: 1px solid gray; */
    /* background-color: #ECF1FE; */
}

.yellowTxt{
    color: #ff7f00!important;
}
.greenTxt{
    color: #84bd3a;
}
.btn{
    background-color: #1a1612!important;
    color: #fff!important;
}
.btn:hover{
    background-color: rgb(205, 212, 212);

    /* background-color: #ff3300!important; */
}
.errorBorder{
    border-color: red!important;
}
.errorText{
    color: red!important;
}