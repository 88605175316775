.active{
    /* background-color: #062539; */
    background-color: #ffff;
    text-decoration: none;
    color: #FF7e00; 
    font-family: 'Mulish', sans-serif;
    font-weight: bold;
    font-size: large;


}
.inactive{
    text-decoration: none;
    color: black; 
   font-family: 'Mulish', sans-serif;
   font-weight: bold; 
   font-size: large;
}
 .active a:hover {
  background-color: black;
   /* opacity: 0.2;  */

}

a:hover {
  /* color: black; */
  color: #FF7e00; 

   /* opacity: 0.2;  */

}
/*
.nav-item .nav-link .a :hover{
  text-decoration: none !important;
  background-color: black;

  }
  .navLink{
    text-decoration: none;
    border-radius: 50em;
  } */